import React, { useState, useEffect } from 'react';
import {
  Button,
  Icon,
  Select,
  Modal,
  Input,
  Divider,
} from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import FilterFormWrapper from './filter-form';
import AuthContainer from '../../../containers/auth';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import Notifications from '../../common/notifications';
import Table from '../../common/table';
import Enums from '../../../constants/enum';
import { getSearchResult, thousandSeparator } from '../../../util/data/converter';
import { getCurrencyFromLocalStorage } from '../../../util/common-util';
import {
  getQuotationStatuses,
  changeQuotationStatus,
  deleteQuotation,
  queryQuotation,
} from '../../../api/quotation';
import QuotationPdfView from '../pdf-view';
import './index.css';

const { Option } = Select;
const { confirm } = Modal;

const QuotationGrid = () => {
  const authService = AuthContainer.useContainer();
  const history = useHistory();
  const [quotations, setQuotations] = useState([]);
  const [searchedQuotations, setSearchedQuotations] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [filterStatuses, setFilterStatuses] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [filterObj, setFilterObj] = useState(null);

  const createQuotation = () => {
    goHere(history, RouteRegistry.quotationCreate.path);
  };

  const copyQuotation = (id) => {
    goHere(history, `${RouteRegistry.quotationCreate.path}?copyId=${id}`);
  };

  const editQuotation = (id) => {
    goHere(history, {
      pathname: `${RouteRegistry.quotation.path}/edit/${id}`,
      state: {
        registryPathname: RouteRegistry.quotationUpdate.path,
      },
    });
  };

  useEffect(() => {
    const gettingQuotations = async () => {
      try {
        setTableDataLoading(true);
        const res = await queryQuotation({ currency: getCurrencyFromLocalStorage() });
        const quotationsWithKey = res.data.map((quotation) => {
          return {
            key: quotation.id,
            ...quotation,
          };
        });
        setQuotations(quotationsWithKey);
        setSearchedQuotations(quotationsWithKey);
        setTableDataLoading(false);
      } catch (error) {
        setTableDataLoading(false);
        Notifications.error('Something went wrong while trying to load quotations.');
      }
    };

    const gettingQuotationStatus = async () => {
      try {
        const res = await getQuotationStatuses();
        setStatuses(res.data);
        const settingStatuses = res.data.map((status) => {
          return {
            text: status.code,
            key: status.code,
          };
        });
        setFilterStatuses(settingStatuses);
      } catch (error) {
        Notifications.error('Something went wrong while trying to load statuses.');
      }
    };
    gettingQuotationStatus();
    gettingQuotations();
  }, []);

  const applyFilter = async (newFilterObj) => {
    try {
      const tempFilter = { ...filterObj };
      const updatedFilterObj = {
        ...tempFilter,
        ...newFilterObj,
        currency: getCurrencyFromLocalStorage(),
      };
      setTableDataLoading(true);
      const response = await queryQuotation(updatedFilterObj);
      setTableDataLoading(false);
      setFilterObj(updatedFilterObj);
      const quotationsWithKey = response.data.map((quotation) => {
        return {
          key: quotation.id,
          ...quotation,
        };
      });
      setQuotations(quotationsWithKey);
      setSearchedQuotations(quotationsWithKey);
    } catch (error) {
      setTableDataLoading(false);
      Notifications.error('Someting went wrong while trying process your query.');
    }
  };

  /**
   * Added a modal instead of new route
   */

  // eslint-disable-next-line no-unused-vars
  const viewQuotation = (id) => {
    goHere(history, {
      pathname: `${RouteRegistry.quotation.path}/view/${id}`,
      state: {
        registryPathname: RouteRegistry.quotationView.path,
      },
    });
  };

  const deleteQuot = async (id) => {
    try {
      await deleteQuotation(id);
      const res = await queryQuotation({ currency: getCurrencyFromLocalStorage() });
      setQuotations(res.data);
      setSearchedQuotations(res.data);
      Notifications.success('Quotation successfully deleted.');
    } catch (error) {
      Notifications.error('Something went wrong while trying to delete Quotation.');
    }
  };

  const confirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this Quotation?',
      content: 'This action will remove all the associated data to this Quotation',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteQuot(id);
      },
    });
  };

  const getQuotationStatusClass = (status) => {
    let statusClass = '';
    switch (status) {
      case Enums.QuotationStatus.PENDING:
        statusClass = 'pending'; break;
      case Enums.QuotationStatus.APPROVED:
        statusClass = 'approved'; break;
      case Enums.QuotationStatus.CANCELLED:
        statusClass = 'cancelled'; break;
      case Enums.QuotationStatus.FULLYINVOICED:
        statusClass = 'fully-invoiced'; break;
      default:
        break;
    }
    return statusClass;
  };

  const getQuotationOptions = () => {
    return statuses.map((status, index) => {
      const key = `option-${index}`;
      if (status.code === Enums.QuotationStatus.PENDING) {
        return <Option value={status.code} key={key} disabled>{status.code.replace(/([A-Z])/g, ' $1')}</Option>;
      }
      return <Option value={status.code} key={key}>{status.code.replace(/([A-Z])/g, ' $1')}</Option>;
    });
  };

  const GridStatusChange = ({ isDisabled, statusClass, record }) => {
    const [loading, setLoading] = useState(false);

    const changeStatus = async (id, value) => {
      try {
        setLoading(true);
        await changeQuotationStatus(id, value);
        setLoading(false);
        const res = await queryQuotation({ currency: getCurrencyFromLocalStorage() });
        const quotationsWithKey = res.data.map((quotation) => {
          return {
            key: quotation.id,
            ...quotation,
          };
        });
        setQuotations(quotationsWithKey);
        setSearchedQuotations(quotationsWithKey);
        Notifications.success('Successfully updated Quotation Status.');
      } catch (error) {
        setLoading(false);
        Notifications.error('Something went wrong while trying to update Quotation Status.');
      }
    };

    const handleQuotationStatusChange = async (id, value) => {
      if (value === Enums.QuotationStatus.CANCELLED) {
        confirm({
          title: 'Are you sure you want to Cancel this Quotation?',
          content: 'This action will put all the associated invoices to ignore state in the Quotation.',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            changeStatus(id, value);
          },
        });
      } else {
        changeStatus(id, value);
      }
    };

    return (
      <div className="table-drop-down-holder">
        <Select
          defaultValue={record.s_code}
          style={{ width: 120 }}
          onChange={(value) => handleQuotationStatusChange(record.q_id, value)}
          className={statusClass}
          disabled={isDisabled}
          loading={loading}
        >
          {getQuotationOptions()}
        </Select>
      </div>
    );
  };

  GridStatusChange.propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    statusClass: PropTypes.string.isRequired,
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  const DeleteAction = ({ record }) => {
    if (
      authService.canDo(Enums.Sections.QUOTATIONS, Enums.Access.DELETE)
      && record.s_code === Enums.QuotationStatus.PENDING
    ) {
      return <Icon type="delete" className="grid-action-icon" onClick={() => { confirmDelete(record.q_id); }} />;
    }
    return null;
  };

  DeleteAction.propTypes = {
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  const GridActions = ({ record }) => {
    const [showPreview, setShowPreview] = useState(false);

    if (record.s_code === Enums.QuotationStatus.CANCELLED) {
      return null;
    }
    return (
      <div className="grid-action-row">
        {authService.canDo(Enums.Sections.QUOTATIONS, Enums.Access.READ) && <Icon type="eye" className="grid-action-icon" onClick={() => { setShowPreview(true); }} />}
        {authService.canDo(Enums.Sections.QUOTATIONS, Enums.Access.UPDATE) && <Icon type="form" className="grid-action-icon" onClick={() => { editQuotation(record.q_id); }} />}
        {authService.canDo(Enums.Sections.QUOTATIONS, Enums.Access.CREATE) && <Icon type="copy" className="grid-action-icon" onClick={() => { copyQuotation(record.q_id); }} />}
        <DeleteAction record={record} />
        <Modal centered visible={showPreview} footer={null} width="90%" onCancel={() => setShowPreview(false)}>
          <QuotationPdfView id={record.q_id} />
        </Modal>
      </div>
    );
  };

  GridActions.propTypes = {
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  const columns = [{
    title: 'Quotation',
    dataIndex: 'q_code',
    key: 'code',
    width: 150,
  }, {
    title: 'Title',
    dataIndex: 'q_title',
    key: 'title',
    width: 350,
    textWrap: 'word-break',
  }, {
    title: 'Client',
    key: 'client',
    dataIndex: 'c_code',
    align: 'center',
  }, {
    title: 'Status',
    key: 'status',
    render: (record) => {
      const statusClass = getQuotationStatusClass(record.s_code);
      return (
        <GridStatusChange
          isDisabled={!authService.canDo(Enums.Sections.QUOTATIONS, Enums.Access.UPDATE)}
          statusClass={statusClass}
          record={record}
        />
      );
    },
    align: 'center',
  }, {
    title: 'Amount',
    dataIndex: 'q_sum',
    key: 'amount',
    width: 200,
    render: (value) => {
      const parsedValue = parseFloat(value).toFixed(Enums.Formats.AmountPrecision);
      return <p className="amount-col">{thousandSeparator(parsedValue)}</p>;
    },
  }, {
    title: 'Action',
    key: 'action',
    render: (record) => {
      return (
        <GridActions record={record} />
      );
    },
    align: 'center',
  }];

  return (
    <div className="content-container">
      {authService.canDo(Enums.Sections.QUOTATIONS, Enums.Access.CREATE)
        && (
          <div className="create-client-btn-container">
            <Button type="primary" icon="plus" size="large" onClick={createQuotation}>
              Create New Quotation
            </Button>
          </div>
        )}
      <FilterFormWrapper
        filterObj={filterObj}
        applyFilter={applyFilter}
        setFilterObj={setFilterObj}
        statuses={filterStatuses}
      />
      <div className="search-wrap">
        <Input.Search
          placeholder="search"
          allowClear
          enterButton
          onSearch={(e) => setSearchedQuotations(getSearchResult(e, quotations, ['q_code', 'q_title', 'c_code', 'q_sum', 's_code']))}
          style={{ width: 200 }}
        />
      </div>
      <Divider />
      <Table
        columns={columns}
        dataSource={searchedQuotations}
        loading={tableDataLoading}
      />
    </div>
  );
};

export default QuotationGrid;
