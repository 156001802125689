import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Divider,
  Icon,
  Input,
  Tag,
  Tooltip,
} from 'antd';
import { useHistory } from 'react-router-dom';
import AuthContainer from '../../../containers/auth';
import FilterFormWrapper from './filter-form';
import InvoicingFormWrapper from './invoicing-form';
import InvoiceFormWrapper from './invoice-form';
import { CategoryFilter } from './category-filter';
import Notifications from '../../common/notifications';
import Table from '../../common/table';
import Enums from '../../../constants/enum';
import { getSearchResult, thousandSeparator } from '../../../util/data/converter';
import { queryInvoices, getInvoiceStatuses } from '../../../api/invoice';
import { getCurrencyFromLocalStorage } from '../../../util/common-util';
import './index.css';
import useSearchParams from '../../hooks/search-params';

const InvoiceGrid = () => {
  const history = useHistory();
  const searchParams = useSearchParams();

  const authService = AuthContainer.useContainer();
  const [invoices, setInvoices] = useState([]);
  const [searchedInvoices, setSearchedInvoices] = useState([]);
  const [filterObj, setFilterObj] = useState(null);
  const [filterCategory, setFilterCategory] = useState(searchParams.get('tab') || 'thisMonth');
  const [bInvoicing, setBInvoicing] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [statuses, setStatuses] = useState([]);
  const [bNewInvoice, setBNewInvoice] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [invoiceTotal, setInvoiceTotal] = useState(0.0);
  const [invoicedTotal, setInvoicedTotal] = useState(0.0);

  const calculateTotal = (invoicesMap) => {
    let invoTotal = 0.0;
    let invoedTotal = 0.0;

    invoicesMap.forEach((invoice) => {
      switch (invoice.s_code) {
        case 'Pending':
          invoTotal += parseFloat(invoice.invoiceAmount);
          break;
        case 'Hold':
          if (invoice.dueAmount !== '0') {
            invoTotal += parseFloat(invoice.dueAmount);
          } else {
            invoTotal += parseFloat(invoice.invoiceAmount);
          }
          break;
        case 'Due':
          invoTotal += parseFloat(invoice.dueAmount);
          break;
        default:
          break;
      }

      invoedTotal += parseFloat(invoice.invoicedAmount);
    });
    setInvoiceTotal(invoTotal);
    setInvoicedTotal(invoedTotal);
  };

  useEffect(() => {
    const gettingInvoices = async () => {
      try {
        setTableDataLoading(true);
        const res = await queryInvoices({
          filterCategory,
          currency: getCurrencyFromLocalStorage(),
        });
        const invoicesWithKey = res.data.map((invoice) => {
          return {
            key: invoice.id,
            ...invoice,
          };
        });
        calculateTotal(invoicesWithKey);
        setInvoices(invoicesWithKey);
        setSearchedInvoices(invoicesWithKey);
        setTableDataLoading(false);
      } catch (error) {
        setTableDataLoading(false);
        Notifications.error('Something went wrong while trying to load invoices!');
      }
    };
    const gettingStatus = async () => {
      try {
        const res = await getInvoiceStatuses();
        const settingStatuses = res.data.map((status) => {
          return {
            text: status.code,
            key: status.code,
          };
        });
        setStatuses(settingStatuses);
      } catch (error) {
        Notifications.error('Something went wrong while trying to load statuses!');
      }
    };
    gettingStatus();
    gettingInvoices();
  }, []);

  const applyFilter = async (newFilterObj) => {
    try {
      const tempFilter = { ...filterObj };
      const updatedFilterObj = {
        ...tempFilter,
        ...newFilterObj,
        currency: getCurrencyFromLocalStorage(),
      };
      setTableDataLoading(true);
      const response = await queryInvoices(updatedFilterObj);
      setFilterObj(updatedFilterObj);
      const invoicesWithKey = response.data.map((invoice) => {
        return {
          key: invoice.id,
          ...invoice,
        };
      });
      calculateTotal(invoicesWithKey);
      setInvoices(invoicesWithKey);
      setSearchedInvoices(invoicesWithKey);
      setTableDataLoading(false);
    } catch (error) {
      setTableDataLoading(false);
      Notifications.error('Someting went wrong while trying process your query.');
    }
  };

  const filterCategoryChange = (category) => {
    searchParams.set('tab', `${category}`);
    searchParams.set('page', '1');
    history.push({ search: searchParams.toString() });
    setFilterCategory(category);
    applyFilter({ filterCategory: category });
  };

  const invoice = (record) => {
    setSelectedInvoice(record);
    setBInvoicing(true);
  };

  const closeInvoicing = () => {
    setBInvoicing(false);
    setSelectedInvoice({});
  };

  const newInvoice = (record) => {
    setBNewInvoice(true);
    setSelectedInvoice(record);
  };

  const closeNewInvoice = () => {
    setBNewInvoice(false);
    setSelectedInvoice({});
  };

  const amountFormat = (amount) => {
    if (amount > 1e20) {
      const prasedAmount = parseFloat(amount.toExponential().split('e')[0] * 1e10).toFixed(Enums.Formats.AmountPrecision);
      return thousandSeparator(prasedAmount);
    }
    const prasedAmount = parseFloat(amount).toFixed(Enums.Formats.AmountPrecision);
    return thousandSeparator(prasedAmount);
  };

  const getRowAction = (record) => {
    const allowedArray = [
      Enums.InvoiceStatus.PENDING,
      Enums.InvoiceStatus.DUE,
      Enums.InvoiceStatus.HOLD,
      Enums.InvoiceStatus.COMPLETED,
      Enums.InvoiceStatus.PARTIALLYINVOICED,
    ];
    const found = allowedArray.filter((status) => {
      return status === record.s_code;
    });
    if (found.length > 0) {
      if (record.s_code === Enums.InvoiceStatus.PARTIALLYINVOICED
        || record.s_code === Enums.InvoiceStatus.COMPLETED) {
        return (
          <div className="grid-action-row">
            {authService.canDo(Enums.Sections.INVOICES, Enums.Access.CREATE) && <Icon type="plus" className="grid-action-icon" onClick={() => { newInvoice(record); }} />}
          </div>
        );
      }
      return (
        <div className="grid-action-row">
          {authService.canDo(Enums.Sections.INVOICES, Enums.Access.UPDATE) && <Icon type="money-collect" className="grid-action-icon" onClick={() => { invoice(record); }} />}
          {authService.canDo(Enums.Sections.INVOICES, Enums.Access.CREATE) && <Icon type="plus" className="grid-action-icon" onClick={() => { newInvoice(record); }} />}
        </div>
      );
    }
    return null;
  };

  const getTag = (record) => {
    const status = record.s_code;
    let tagColor = '';
    switch (status) {
      case Enums.InvoiceStatus.PENDING:
        tagColor = 'orange'; break;
      case Enums.InvoiceStatus.PARTIALLYINVOICED:
        tagColor = 'blue'; break;
      case Enums.InvoiceStatus.DUE:
        tagColor = 'red'; break;
      case Enums.InvoiceStatus.COMPLETED:
        tagColor = 'green'; break;
      case Enums.InvoiceStatus.IGNORE:
        tagColor = 'gray'; break;
      default:
        break;
    }
    return (
      <Tag color={tagColor} key={record.id}>
        {status.replace(/([A-Z])/g, ' $1')}
      </Tag>
    );
  };

  const columns = [
    {
      title: 'Quotation',
      key: 'q_code',
      width: 150,
      render: (record) => {
        return (
          <Tooltip placement="right" title={record.q_title} className="grid-tooltip">
            {record.q_code}
          </Tooltip>
        );
      },
    },
    {
      title: 'Client',
      key: 'c_code',
      dataIndex: 'c_code',
      align: 'center',
    }, {
      title: 'InvoiceDate',
      key: 'invoiceDate',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        return moment(a.invoiceDate).format('x') - moment(b.invoiceDate).format('x');
      },
      render: (record) => {
        return moment(record.invoiceDate).format(Enums.Formats.DateFormat);
      },
    },
    {
      title: 'Amount',
      dataIndex: 'invoiceAmount',
      key: 'invoiceAmount',
      width: 150,
      render: (value) => {
        const parsedValue = parseFloat(value).toFixed(Enums.Formats.AmountPrecision);
        return <p className="amount-col">{thousandSeparator(parsedValue)}</p>;
      },
    },
    {
      title: 'Invoiced Amount',
      dataIndex: 'invoicedAmount',
      key: 'invoicedAmount',
      width: 150,
      render: (value) => {
        const parsedValue = parseFloat(value).toFixed(Enums.Formats.AmountPrecision);
        return <p className="amount-col">{thousandSeparator(parsedValue)}</p>;
      },
    },
    {
      title: 'Due',
      dataIndex: 'dueAmount',
      key: 'dueAmount',
      width: 150,
      render: (value) => {
        const parsedValue = parseFloat(value).toFixed(Enums.Formats.AmountPrecision);
        return <p className="amount-col">{thousandSeparator(parsedValue)}</p>;
      },
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      textWrap: 'word-break',
      width: 250,
    },
    {
      title: 'Status',
      render: (record) => {
        return (
          <>{getTag(record)}</>
        );
      },
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => {
        return (
          <>{getRowAction(record)}</>
        );
      },
      align: 'center',
    },
  ];

  const getInvoicingForm = () => {
    if (!bInvoicing) {
      return null;
    }
    return (
      <InvoicingFormWrapper
        bInvoicing={bInvoicing}
        record={selectedInvoice}
        closeInvoicing={closeInvoicing}
        statuses={statuses}
        applyFilter={applyFilter}
      />
    );
  };

  const getInvoiceForm = () => {
    if (!bNewInvoice) {
      return null;
    }
    return (
      <InvoiceFormWrapper
        bNewInvoice={bNewInvoice}
        record={selectedInvoice}
        closeInvoiceForm={closeNewInvoice}
        applyFilter={applyFilter}
      />
    );
  };

  const WidgetItem = ({ title, value, customClass }) => {
    return (
      <div className="widget-item-invoice">
        <div className={customClass} />
        <span>{title}</span>
        <p className="widget-amount">
          $
          {value}
        </p>
      </div>
    );
  };

  WidgetItem.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    customClass: PropTypes.string.isRequired,
  };

  return (
    <>
      <div className="widget-holder-invoice">
        {invoiceTotal ? <WidgetItem title="TO BE INVOICED" value={amountFormat(invoiceTotal)} customClass="widget-ribbon-blue" /> : null}
        {invoicedTotal ? <WidgetItem title="INVOICED" value={amountFormat(invoicedTotal)} customClass="widget-ribbon-green" /> : null}
      </div>
      <div className="content-container">
        <CategoryFilter
          filterCategory={filterCategory}
          categroyChange={filterCategoryChange}
        />
        <FilterFormWrapper
          filterObj={filterObj}
          applyFilter={applyFilter}
          setFilterObj={setFilterObj}
          statuses={statuses}
          filterCategory={filterCategory}
        />
        {getInvoicingForm()}
        {getInvoiceForm()}
        <Divider />
        <div style={{ textAlign: 'left' }}>
          <Input.Search
            placeholder="search"
            allowClear
            enterButton
            onSearch={(e) => setSearchedInvoices(getSearchResult(e, invoices, ['q_code', 'q_title', 'c_code', 'invoiceDate', 'invoiceAmount', 'dueAmount', 'note', 's_code']))}
            style={{ width: 200 }}
          />
        </div>
        <Divider />
        <Table
          columns={columns}
          dataSource={searchedInvoices}
          loading={tableDataLoading}
        />
      </div>
    </>
  );
};

export default InvoiceGrid;
