import React from 'react';
import {
  View,
  Page,
  Document,
  Image,
  Text,
} from '@react-pdf/renderer';
import moment from 'moment';
import Enums from '../../../constants/enum';
import { getCurrencyFromLocalStorage } from '../../../util/common-util';
import styles from './style';
import fidenzLogo from '../../../assets/images/full-logo.png';

export default (invoice) => {
  const getHeaderSection = () => {
    const client = invoice ? invoice.client : {};
    const invoiceCode = invoice ? invoice.invoiceNo : '';
    const invoiceDate = invoice ? moment(invoice.invoiceDate).format(Enums.Formats.DateFormat) : '';
    const dueDate = invoice ? moment(invoice.dueDate).format(Enums.Formats.DateFormat) : '';
    return (
      <>
        <View style={styles.headerSection}>
          <View style={styles.headerRow}>
            <View style={styles.headerLogoLeft}>
              <Image src={fidenzLogo} />
            </View>
            <View style={[styles.headerTopicRight]}>
              <Text style={styles.topHeaderRightTitle}>Invoice</Text>
              <Text style={styles.topHeaderRightTileSub}>{`Fidenz-${getCurrencyFromLocalStorage()}`}</Text>
            </View>
          </View>
          <View style={styles.headerRow}>
            <View style={[styles.leftAlign]}>
              <Text style={styles.headerAddressHeader}>Customer</Text>
              <Text style={styles.headerAddressField}>{client.name}</Text>
              <Text style={styles.headerAddressField}>{`${client.addressLineOne},`}</Text>
              {(client.addressLineTwo) ? <Text style={styles.headerAddressField}>{`${client.addressLineTwo},`}</Text> : null}
              <Text style={styles.headerAddressField}>{`${client.city}, ${client.zipCode}, ${client.country}.`}</Text>
              {(client.contactPersonName) ? <Text style={styles.headerAddressField}>{`${client.contactPersonName}`}</Text> : null}
              {client.phone ? <Text style={styles.headerAddressField}>{`${client.phone}`}</Text> : null}
              <Text style={styles.headerAddressField}>{`${client.email}`}</Text>
            </View>
            <View style={[styles.rightAlign]}>
              <Text style={styles.headerAddressHeader}>From</Text>
              <Text style={styles.headerAddressField}>Fidenz Private Limited</Text>
              <Text style={styles.headerAddressField}>No: 239, Nawala Road,</Text>
              <Text style={styles.headerAddressField}>Nawala, 10120, Sri Lanka.</Text>
              <Text style={styles.headerAddressField}>info@fidenz.com</Text>
            </View>
          </View>
          <View style={styles.quotationMetaHolder}>
            <Text style={styles.headerDateField}>
              {`Invoice No: ${invoiceCode}`}
            </Text>
            <Text style={styles.headerDateField}>
              {`Invoice Date: ${invoiceDate}`}
            </Text>
            <Text style={styles.headerDateField}>
              {`Due Date: ${dueDate}`}
            </Text>
          </View>
        </View>
      </>
    );
  };

  const thousandsSeparators = (num) => {
    const numParts = num.toString().split('.');
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return numParts.join('.');
  };

  const getTwoColGridRow = (
    numberColVal,
    firstColVal,
    secondColVal,
    isHeader,
    isTotalRow,
    colStyleOvrr = {},
  ) => {
    const colTextStyle = isHeader ? styles.colHeader : styles.colData;
    const totalCol = isTotalRow ? styles.totalCol : {};
    const finalColTextStyle = isHeader ? styles.textCenter : styles.textRight;
    const firstColTextStyle = isHeader ? styles.textCenter : {};
    const totalRowTextStyle = isTotalRow ? styles.totalColText : {};
    return (
      <View style={[styles.customGridRow]}>
        <View style={[
          styles.customGridCol,
          styles.numberCol,
          styles.textCenter,
          totalCol,
          colStyleOvrr.firstCol,
        ]}
        >
          <Text style={colTextStyle}>{numberColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.textCol,
          totalCol,
          colStyleOvrr.secondCol,
          firstColTextStyle,
        ]}
        >
          <Text style={[colTextStyle, totalRowTextStyle]}>{firstColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.amountCol,
          styles.finalCol,
          finalColTextStyle,
          totalCol,
          colStyleOvrr.thridCol,
        ]}
        >
          <Text style={[colTextStyle, totalRowTextStyle]}>{secondColVal}</Text>
        </View>
      </View>
    );
  };

  const getFiveColGridRow = (
    numberColVal,
    firstColVal,
    secondColVal,
    thirdColVal,
    fourthColVal,
    fifthColVal,
    isHeader,
    isTotalRow,
    colStyleOvrr = {},
  ) => {
    const colTextStyle = isHeader ? styles.colHeader : styles.colData;
    const totalCol = isTotalRow ? styles.totalCol : {};
    const finalColTextStyle = isHeader ? styles.textCenter : styles.textRight;
    const firstColTextStyle = isHeader ? styles.textCenter : {};
    return (
      <View style={[styles.customGridRow]}>
        <View style={[
          styles.customGridCol,
          styles.numberCol,
          styles.textCenter,
          totalCol,
          colStyleOvrr.firstCol,
        ]}
        >
          <Text style={colTextStyle}>{numberColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.midTextCol,
          totalCol,
          colStyleOvrr.secondCol,
          firstColTextStyle,
        ]}
        >
          <Text style={colTextStyle}>{firstColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.amountCol,
          totalCol,
          styles.textCenter,
          colStyleOvrr.secondCol,
        ]}
        >
          <Text style={colTextStyle}>{secondColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.numberCol,
          styles.textCenter,
          totalCol,
          colStyleOvrr.thridCol,
        ]}
        >
          <Text style={colTextStyle}>{thirdColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.amountCol,
          styles.finalCol,
          finalColTextStyle,
          totalCol,
          colStyleOvrr.fourthCol,
        ]}
        >
          <Text style={colTextStyle}>{fourthColVal}</Text>
        </View>
        <View style={[
          styles.customGridCol,
          styles.amountCol,
          styles.finalCol,
          finalColTextStyle,
          totalCol,
          colStyleOvrr.fifthCol,
        ]}
        >
          <Text style={colTextStyle}>{fifthColVal}</Text>
        </View>
      </View>
    );
  };

  const getInvoiceRows = () => {
    const invoices = invoice ? invoice.invoices : [];
    let total = 0;
    const rows = invoices.map((inv, index) => {
      total += parseFloat(inv.invoicedAmount);
      const indexVal = (index < 10) ? `0${index + 1}` : index + 1;
      const { productCategory, invoicedAmount, quotation } = inv;
      const amount = parseFloat(invoicedAmount)
        .toFixed(Enums.Formats.AmountPrecision);
      const rowTotal = 1 * amount;
      return getFiveColGridRow(
        indexVal,
        productCategory ? productCategory.name : '-',
        quotation.code,
        1,
        thousandsSeparators(amount),
        thousandsSeparators(rowTotal),
      );
    });
    const formattedTotal = total.toFixed(Enums.Formats.AmountPrecision);
    const totalRow = getTwoColGridRow('', 'Total', thousandsSeparators(formattedTotal), false, true);
    return [...rows, totalRow];
  };

  const getInvoiceItems = () => {
    return (
      <View style={styles.gridSection} wrap={false}>
        <View style={styles.gridTop} />
        {getFiveColGridRow('No', 'Product', 'Quotation', 'Qty', `Price (${getCurrencyFromLocalStorage()})`, `Amount (${getCurrencyFromLocalStorage()})`, true)}
        {getInvoiceRows()}
      </View>
    );
  };

  const getNotes = () => {
    const client = invoice ? invoice.client : {};
    const bankDetails = client ? client.bankDetails : {};
    return (
      <View style={styles.finalSection} wrap={false}>
        <Text style={styles.termsSectionTitle}>
          Notes / Terms:
        </Text>
        <Text style={styles.termsSectionTerms}>{`Amount in ${getCurrencyFromLocalStorage()}`}</Text>
        <Text style={styles.termsSectionTerms}>Holder: Fidenz Pvt Ltd. (PV 74438)</Text>
        <Text style={styles.termsSectionTerms}>{`Acc No: ${bankDetails ? bankDetails.accountNumber : ''}`}</Text>
        <Text style={styles.termsSectionTerms}>{`SWIFT Code: ${bankDetails ? bankDetails.swiftCode : ''}`}</Text>
      </View>
    );
  };

  const invoiceCode = invoice ? invoice.invoiceNo : '';
  const renderPDF = () => {
    return (
      <Document title={invoiceCode}>
        <Page size="A4" style={styles.page} wrap>
          {getHeaderSection()}
          {getInvoiceItems()}
          {getNotes()}
        </Page>
      </Document>
    );
  };

  return renderPDF();
};
