import axios from './api-controller';

const getCompanies = async () => {
  const response = await axios.get('company');
  return response;
};

const getCompany = async (id) => {
  const response = await axios.get(`/company/${id}`);
  return response;
};

const createCompany = async (newCompany) => {
  const response = await axios.post('/company', newCompany);
  return response;
};

const updateCompany = async (id, updatedCompany) => {
  const response = await axios.put(`/company/${id}`, updatedCompany);
  return response;
};

const deleteCompany = async (id) => {
  const response = await axios.delete(`/company/${id}`);
  return response;
};

export {
  getCompanies,
  getCompany,
  createCompany,
  updateCompany,
  deleteCompany,
};
