import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';

import getPDFDoc from './pdf-doc';
import { getQuotation } from '../../../api/quotation';

import './index.css';

const QuotationPdfView = ({ id }) => {
  const [quotation, setQuotation] = useState(null);
  const [docLoading, setDocLoading] = useState(true);
  const [downloadLinkLoading, setDownloadLinkLoading] = useState(true);

  useEffect(() => {
    const getQuot = async () => {
      if (id) {
        const resposnse = await getQuotation(id);
        setQuotation(resposnse.data);
      }
    };
    getQuot();
  }, [id]);

  useEffect(() => {
    console.log('renderer of data load and document load...', quotation ? quotation.code : null);
    const timerHandler = setTimeout(() => {
      if (docLoading) {
        setDocLoading(false);
      }
    }, 1500);

    return () => clearInterval(timerHandler);
  }, [quotation]);

  useEffect(() => {
    console.log('renderer of document download...');
    const timerHandler = setTimeout(() => {
      setDownloadLinkLoading(false);
    }, 5000);

    return () => clearInterval(timerHandler);
  }, [docLoading]);

  // eslint-disable-next-line no-unused-vars
  const getDownloadComp = () => {
    if (quotation) {
      const docName = `${quotation.code} - ${quotation.client.code} - ${quotation.title}.pdf`;
      if (!downloadLinkLoading) {
        return (
          <div className="pdf-download-btn-holder">
            <PDFDownloadLink key={Math.random()} document={getPDFDoc(quotation)} fileName={docName} className="pdf-download-btn">
              {({ loading }) => (loading ? 'Preparing document...' : 'Download PDF')}
            </PDFDownloadLink>
          </div>
        );
      }
    }
    return (
      <div className="pdf-download-btn-holder">
        <div className="pdf-download-btn">Preparing document...</div>
      </div>
    );
  };

  const getComp = () => {
    if (docLoading) {
      return (
        <Spin tip="Loading..." />
      );
    }
    const DOC = getPDFDoc(quotation);
    return (
      <>
        {/* {getDownloadComp()} */}
        <PDFViewer key={Math.random} width="100%" height="1500px" className="pdf-viewer">
          {DOC}
        </PDFViewer>
      </>
    );
  };

  return (
    <div className="content-container">
      {getComp()}
    </div>
  );
};

QuotationPdfView.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number]).isRequired,
};

export default QuotationPdfView;
