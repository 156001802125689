import React, { useState, useEffect } from 'react';
import { Form, Button } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { InputElem, SelectBox } from '../../common/form-elements';
import Notifcations from '../../common/notifications';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import PageReload from '../../currency/page-reload-message';
import 'antd/dist/antd.css';
import './index.css';
import { createCompany, getCompany, updateCompany } from '../../../api/company';
import Countries from '../../../util/data/country-reader';

const CompanyForm = ({ form, match }) => {
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [existingCompany, setExistingCompany] = useState();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getAndSetCompany = async () => {
      if (match.params.id) {
        const res = await getCompany(match.params.id);
        setExistingCompany(res.data);
      }
    };

    const prepareCountries = () => {
      const countrySet = Countries.map((country) => {
        return {
          text: country.text,
          key: country.text,
        };
      });
      setCountries(countrySet);
    };

    prepareCountries();
    getAndSetCompany();
  }, [match.params.id]);

  const saveCompany = async (values) => {
    try {
      setSubmitting(true);

      if (existingCompany) {
        await updateCompany(existingCompany.id, values);
        Notifcations.success('Company successfully updated.');
      } else {
        await createCompany(values);
        Notifcations.success('Company successfully created.');
      }

      setSubmitting(false);
      PageReload(() => {
        goHere(history, RouteRegistry.company.path);
      });
    } catch (error) {
      setSubmitting(false);
      if (error.response.status === 409) {
        Notifcations.error(error.response.data.message);
      } else {
        Notifcations.error('Something went wrong while managing your company.');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        saveCompany(values);
      }
    });
  };

  return (
    <div className="content-container">
      <div className="form">
        <Form onSubmit={handleSubmit}>
          <InputElem
            form={form}
            fieldName="name"
            rules={[
              {
                required: true,
                message: 'Please enter the company name',
              },
            ]}
            placeholder="Company Name*"
            type="text"
            initialValue={existingCompany ? existingCompany.name : null}
          />
          <InputElem
            form={form}
            fieldName="addressLineOne"
            rules={[
              { required: true, message: "Please enter country's address" },
            ]}
            placeholder="Address Line 1*"
            type="text"
            initialValue={
              existingCompany ? existingCompany.addressLineOne : null
            }
          />
          <InputElem
            form={form}
            fieldName="addressLineTwo"
            placeholder="Address Line 2"
            type="text"
            initialValue={
              existingCompany ? existingCompany.addressLineTwo : null
            }
          />
          <InputElem
            form={form}
            fieldName="city"
            rules={[
              { required: true, message: "Please enter country's town/city" },
            ]}
            placeholder="Town / City*"
            type="text"
            initialValue={existingCompany ? existingCompany.city : null}
          />
          <InputElem
            form={form}
            fieldName="state"
            placeholder="State / Province"
            type="text"
            initialValue={existingCompany ? existingCompany.state : null}
          />
          <InputElem
            form={form}
            fieldName="zipCode"
            rules={[
              { required: true, message: "Please enter country's zip code" },
            ]}
            placeholder="Zip Code*"
            type="text"
            initialValue={existingCompany ? existingCompany.zipCode : null}
          />
          <SelectBox
            form={form}
            fieldName="country"
            placeholder="Country*"
            options={countries}
            rules={[
              { required: true, message: "Please select country's country" },
            ]}
            initialValue={existingCompany ? existingCompany.country : null}
          />
          <InputElem
            form={form}
            fieldName="email"
            rules={[
              { required: true, message: 'Please enter company email' },
              // eslint-disable-next-line no-useless-escape, max-len
              // { message: 'Please enter valid email', pattern: '/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/' },
            ]}
            placeholder="Company Email*"
            type="text"
            initialValue={existingCompany ? existingCompany.email : null}
          />
          <Form.Item className="no-bottom-gutter">
            <Button
              type="primary"
              htmlType="submit"
              className="button-right"
              size="large"
              loading={isSubmitting}
            >
              {existingCompany ? 'Update' : 'Create'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

CompanyForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const CompanyFormWrapper = Form.create({ name: 'company-form' })(CompanyForm);

export default CompanyFormWrapper;
