import React, { useState, useEffect } from 'react';
import {
  Button,
  Icon,
  Divider,
  Modal,
  Tag,
  Input,
} from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import Notifications from '../../common/notifications';
import { getBanksDetailsWithClientCount, deleteBankDetails } from '../../../api/bank-details';
import Table from '../../common/table';
import './grid.css';
import { getSearchResult } from '../../../util/data/converter';

const BankDetailsGrid = () => {
  const history = useHistory();
  const [bankDetails, setBankDetails] = useState([]);
  const [searchedBankDetails, setSearchedBankDetails] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);

  const addBankDetails = () => {
    goHere(history, RouteRegistry.bankDetailsCreate.path);
  };

  useEffect(() => {
    const gettingBankDetails = async () => {
      try {
        setTableDataLoading(true);
        const res = await getBanksDetailsWithClientCount({});
        setBankDetails(res.data);
        setSearchedBankDetails(res.data);
        setTableDataLoading(false);
      } catch (error) {
        console.log(error);
        setTableDataLoading(false);
        Notifications.error('Something went wrong while trying to load bank details!');
      }
    };
    gettingBankDetails();
  }, []);

  const editBankDetails = (id) => {
    goHere(history, {
      pathname: `${RouteRegistry.bankDetails.path}/edit/${id}`,
      state: {
        registryPathname: RouteRegistry.bankDetailsUpdate.path,
      },
    });
  };

  const deleteSelectedBankDetails = async (id) => {
    try {
      setTableDataLoading(true);
      await deleteBankDetails(id);
      const res = await getBanksDetailsWithClientCount({});
      setBankDetails(res.data);
      setSearchedBankDetails(res.data);
      Notifications.success('Bank Details successfully deleted.');
      setTableDataLoading(false);
    } catch (error) {
      Notifications.error('Something went wrong while trying to delete Bank Details.');
      setTableDataLoading(false);
    }
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Delete Bank Details?',
      content: 'Are you sure you want to delete this Bank Details?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteSelectedBankDetails(id);
      },
    });
  };

  const GridActions = ({ record }) => {
    return (
      <div className="grid-action-row">
        <Icon type="form" className="grid-action-icon" onClick={() => { editBankDetails(record.id); }} />
        {record.clientsCount > 0
          ? <Tag color="#f5222d">Assigned to Client</Tag>
          : (
            <Icon type="delete" className="grid-action-icon" onClick={() => { confirmDelete(record.id); }} />
          )}
      </div>
    );
  };

  GridActions.propTypes = {
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  const columns = [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Branch',
    dataIndex: 'branch',
    key: 'branch',
    align: 'center',
  },
  {
    title: 'Account No',
    dataIndex: 'accountNumber',
    key: 'accountNumber',
  },
  {
    title: 'Swift Code',
    dataIndex: 'swiftCode',
    key: 'swiftCode',
  },
  {
    title: 'Action',
    key: 'action',
    render: (record) => {
      return (<GridActions record={record} />);
    },
    align: 'center',
  }];

  return (
    <div className="content-container">
      <div className="create-bank-details-btn-container">
        <Button type="primary" icon="plus" size="large" onClick={addBankDetails}>
          Add New Bank
        </Button>
      </div>
      <Divider />
      <div style={{ textAlign: 'left' }}>
        <Input.Search
          placeholder="search"
          allowClear
          enterButton
          onSearch={(e) => setSearchedBankDetails(getSearchResult(e, bankDetails, ['name', 'branch', 'accountNumber', 'swiftCode']))}
          style={{ width: 200 }}
        />
      </div>
      <Divider />
      <Table
        columns={columns}
        dataSource={searchedBankDetails}
        loading={tableDataLoading}
      />
    </div>
  );
};

export default BankDetailsGrid;
