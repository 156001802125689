import React, { useState, useEffect } from 'react';
import {
  Button, Icon, Modal, Divider, Input,
} from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import Notifications from '../../common/notifications';
import Table from '../../common/table';
import PageReload from '../../currency/page-reload-message';
import './grid.css';
import { getSearchResult } from '../../../util/data/converter';
import { deleteCompany, getCompanies } from '../../../api/company';

const CompanyGrid = () => {
  const [companies, setCompanies] = useState([]);
  const [searchedCompanies, setSearchedCompanies] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(false);

  const history = useHistory();

  const createCompany = () => {
    goHere(history, RouteRegistry.companyCreate.path);
  };

  const editCompany = (id) => {
    goHere(history, {
      pathname: `${RouteRegistry.company.path}/edit/${id}`,
      state: {
        registryPathname: RouteRegistry.companyUpdate.path,
      },
    });
  };

  const deleteSelectedCompany = async (id) => {
    try {
      setTableDataLoading(true);
      await deleteCompany(id);
      Notifications.success('Company successfully deleted.');
      setTableDataLoading(false);
      PageReload(() => {});
    } catch (error) {
      Notifications.error(
        'Something went wrong while trying to delete Company.',
      );
      setTableDataLoading(false);
    }
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Delete Company?',
      content: 'Are you sure you want to delete this Company?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteSelectedCompany(id);
      },
    });
  };

  useEffect(() => {
    const gettingCompanies = async () => {
      try {
        setTableDataLoading(true);
        const { data } = await getCompanies();
        setCompanies(data);
        setSearchedCompanies(data);
        setTableDataLoading(false);
      } catch (error) {
        setTableDataLoading(false);
        Notifications.error('Something went wrong while trying to load companies!');
      }
    };
    gettingCompanies();
  }, []);

  const GridActions = ({ record }) => (
    <div className="grid-action-row">
      <Icon
        type="form"
        className="grid-action-icon"
        onClick={() => {
          editCompany(record.id);
        }}
      />
      <Icon
        type="delete"
        className="grid-action-icon"
        onClick={() => {
          confirmDelete(record.id);
        }}
      />
    </div>
  );

  GridActions.propTypes = {
    record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      align: 'left',
      render: (
        _,
        {
          addressLineOne: a1, addressLineTwo: a2, city, state, zipCode, country,
        },
      ) => (
        <span>{`${a1}, ${a2 ? `${a2},` : ''} ${city}, ${state ? `${state},` : ''} ${zipCode}, ${country}`}</span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 300,
      render: (record) => {
        return <GridActions record={record} />;
      },
    },
  ];

  return (
    <div className="content-container">
      <div className="create-company-btn-container">
        <Button type="primary" icon="plus" size="large" onClick={createCompany}>
          Add New Company
        </Button>
      </div>
      <Divider />
      <div style={{ textAlign: 'left' }}>
        <Input.Search
          placeholder="search"
          allowClear
          enterButton
          onSearch={(e) => setSearchedCompanies(getSearchResult(e, companies, ['code', 'name']))}
          style={{ width: 200 }}
        />
      </div>
      <Divider />
      <Table
        columns={columns}
        dataSource={searchedCompanies}
        loading={tableDataLoading}
      />
    </div>
  );
};

export default CompanyGrid;
