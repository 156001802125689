import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  InputElem,
  SelectBox,
} from '../../common/form-elements';
import Notifcations from '../../common/notifications';
import {
  createClient,
  getClient,
  updateClient,
} from '../../../api/client';
import goHere from '../../../router/go-here';
import RouteRegistry from '../../../router/registry';
import { getBanksDetails } from '../../../api/bank-details';
import Countries from '../../../util/data/country-reader';
import { getCompanies } from '../../../api/company';
import 'antd/dist/antd.css';
import './index.css';

const ClientForm = ({ form, match }) => {
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const [existingClient, setExistingClient] = useState(null);
  const [countries, setCountries] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const getAndSetClient = async () => {
      if (match.params.id) {
        const res = await getClient(match.params.id);
        setExistingClient(res.data);
      }
    };

    const getAndSetCompanies = async () => {
      const { data } = await getCompanies();
      setCompanies(data.map((c) => ({ text: c.name, key: c.id })));
    };

    const prepareCountries = () => {
      const countrySet = Countries.map((country) => {
        return {
          text: country.text,
          key: country.text,
        };
      });
      setCountries(countrySet);
    };

    const prepareBankDetails = async () => {
      const response = await getBanksDetails({});
      const { data } = response;
      const updatedBankDetail = data.map((bankDetail) => {
        return {
          text: bankDetail.name,
          key: bankDetail.accountNumber,
        };
      });
      setBankDetails(updatedBankDetail);
    };

    prepareBankDetails();
    prepareCountries();
    getAndSetClient();
    getAndSetCompanies();
    // eslint-disable-next-line
  }, [])

  const saveClient = async (values) => {
    try {
      setSubmitting(true);
      const clientObj = {
        name: values.clientName,
        code: values.clientCode,
        email: values.clientMail,
        contactPersonName: values.contactPersonName,
        phone: values.clientContactNumer,
        addressLineOne: values.clientAddressOne,
        addressLineTwo: values.clientAddressTwo,
        city: values.clientCity,
        state: values.clientState,
        zipCode: values.clientZipCode,
        country: values.clientCountry,
        remarks: values.clientRemark,
        bankDetails: values.accountNumber,
        company: {
          id: values.clientCompany,
        },
      };
      if (existingClient) {
        const clientObjWithId = { id: existingClient.id, ...clientObj };
        await updateClient(clientObjWithId.id, clientObjWithId);
        Notifcations.success('Client successfully updated.');
      } else {
        await createClient(clientObj);
        Notifcations.success('Client successfully created.');
      }
      setSubmitting(false);
      goHere(history, RouteRegistry.client.path);
    } catch (error) {
      setSubmitting(false);
      if (error.response.status === 409) {
        Notifcations.error(error.response.data.message);
      } else {
        Notifcations.error('Something went wrong while managing your client.');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        saveClient(values);
      }
    });
  };

  const formSubmitButtonText = () => {
    if (existingClient) {
      return 'Update';
    }
    return 'Create';
  };

  return (
    <div className="content-container">
      <div className="form">
        <Form onSubmit={handleSubmit} className="clients-form">
          <InputElem
            form={form}
            fieldName="clientName"
            rules={[
              { required: true, message: 'Please enter client\'s name' },
            ]}
            placeholder="Client Name*"
            type="text"
            initialValue={existingClient ? existingClient.name : null}
          />
          <SelectBox
            form={form}
            fieldName="clientCompany"
            placeholder="Company*"
            options={companies}
            rules={[
              { required: true, message: 'Please select client\'s company' },
            ]}
            initialValue={
              existingClient && existingClient.company
                ? existingClient.company.id
                : null
            }
          />
          <InputElem
            form={form}
            fieldName="clientCode"
            rules={[
              { required: true, message: 'Please enter the client\'s code' },
            ]}
            placeholder="Client Code*"
            type="text"
            initialValue={existingClient ? existingClient.code : null}
          />
          <InputElem
            form={form}
            fieldName="clientMail"
            rules={[
              { required: true, message: 'Please enter client\'s email' },
              { type: 'email', message: 'Enter a valid email' },
            ]}
            placeholder="Email Address*"
            type="text"
            initialValue={existingClient ? existingClient.email : null}
          />
          <InputElem
            form={form}
            fieldName="contactPersonName"
            placeholder="Contact Person"
            type="text"
            initialValue={existingClient ? existingClient.contactPersonName : ''}
          />
          <InputElem
            form={form}
            fieldName="clientContactNumer"
            rules={[
              { pattern: /^[+\d]?(?:[\d-.\s()]*)$/, message: 'Please enter a valid contact number' },
            ]}
            placeholder="Contact Number"
            type="text"
            initialValue={existingClient ? existingClient.phone : null}
          />
          <InputElem
            form={form}
            fieldName="clientAddressOne"
            rules={[
              { required: true, message: 'Please enter client\'s address' },
            ]}
            placeholder="Address Line 1*"
            type="text"
            initialValue={existingClient ? existingClient.addressLineOne : null}
          />
          <InputElem
            form={form}
            fieldName="clientAddressTwo"
            placeholder="Address Line 2"
            type="text"
            initialValue={existingClient ? existingClient.addressLineTwo : null}
          />
          <InputElem
            form={form}
            fieldName="clientCity"
            rules={[
              { required: true, message: 'Please enter client\'s town/city' },
            ]}
            placeholder="Town / City*"
            type="text"
            initialValue={existingClient ? existingClient.city : null}
          />
          <InputElem
            form={form}
            fieldName="clientState"
            placeholder="State / Province"
            type="text"
            initialValue={existingClient ? existingClient.state : null}
          />
          <InputElem
            form={form}
            fieldName="clientZipCode"
            rules={[
              { required: true, message: 'Please enter client\'s zip code' },
            ]}
            placeholder="Zip Code*"
            type="text"
            initialValue={existingClient ? existingClient.zipCode : null}
          />
          <SelectBox
            form={form}
            fieldName="clientCountry"
            placeholder="Country*"
            options={countries}
            rules={[
              { required: true, message: 'Please select client\'s country' },
            ]}
            initialValue={existingClient ? existingClient.country : null}
          />
          <InputElem
            form={form}
            fieldName="clientRemark"
            placeholder="Remarks"
            type="text"
            initialValue={existingClient ? existingClient.remarks : null}
            area
          />
          <SelectBox
            form={form}
            fieldName="accountNumber"
            placeholder="Bank Account"
            options={bankDetails}
            rules={[
              { required: true, message: 'Please select a Bank Account' },
            ]}
            initialValue={existingClient && existingClient.bankDetails ? existingClient.bankDetails.accountNumber : ''}
          />
          <Form.Item className="no-bottom-gutter">
            <Button type="primary" htmlType="submit" className="clients-form-button button-right" size="large" loading={isSubmitting}>
              {formSubmitButtonText()}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

ClientForm.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const ClientFormWrapper = Form.create({ name: 'clients-form' })(ClientForm);

export default ClientFormWrapper;
