import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table as AntTable,
} from 'antd';
import { useHistory } from 'react-router-dom';
import useSearchParams from '../../hooks/search-params';
import './index.css';

const Table = ({ columns, dataSource, loading }) => {
  const [current, setCurrent] = useState();
  const history = useHistory();
  const searchParams = useSearchParams();

  useEffect(() => {
    setCurrent(Number(searchParams.get('page')));
  }, [searchParams]);

  const handleChange = (page) => {
    searchParams.set('page', `${page}`);
    history.push({ search: searchParams.toString() });
  };

  return (
    <div className="table-wrapper">
      <AntTable
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        bordered
        pagination={{ current, onChange: handleChange }}
      />
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  dataSource: PropTypes.oneOfType([PropTypes.array]).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Table;
